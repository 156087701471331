import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { Search } from '@element-plus/icons-vue';
import { ElMessage } from 'element-plus';
export default {
  components: {},
  data: function data() {
    return {
      Search: Search,
      userInfo: {
        givenName: '',
        phoneNumber: ''
      },
      loading: false,
      searchVal: '',
      params: {
        page: 1,
        limit: 5
      },
      total: 0,
      tabIndex: 0,
      typeList: [],
      typeId: null,
      dataList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // 设置userinfo
    var userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

    if (userInfo && userInfo !== 'null') {
      this.userInfo = userInfo;
    } // 获取tab分类


    this.getTypeList();
  },
  methods: {
    tabClick: function tabClick(item, i) {
      this.tabIndex = i;
      this.typeId = item.id;
      this.params.page = 1;
      this.getList();
    },
    change: function change() {
      console.log(this.searchVal);
    },
    handleCurrentChange: function handleCurrentChange(event) {
      this.params.page = event;
      this.getList();
    },
    getTypeList: function getTypeList() {
      var _this = this;

      this.$axios.get('/api/article-category/list-guest', {
        PlatformType: 1,
        Index: 1,
        Size: 99
      }).then(function (res) {
        if (res.code === 0) {
          _this.typeList = res.data.list;

          if (res.data.list && res.data.list.length > 0) {
            // 请求列表
            _this.typeId = res.data.list[0].id;

            _this.getList(res.data.list[0].id);
          }
        } else {
          ElMessage({
            message: res.message || '操作失败',
            type: 'warning'
          });
        }
      });
    },
    getList: function getList(id) {
      var _this2 = this;

      this.loading = true;
      this.$axios.get('/api/article/list-bycategory-guest', {
        CategoryId: id || this.typeId,
        Index: this.params.page,
        Size: this.params.limit
      }).then(function (res) {
        _this2.loading = false;

        if (res.code === 0) {
          _this2.total = res.data.total;
          _this2.dataList = res.data.list;
        } else {
          ElMessage({
            message: res.message || '操作失败',
            type: 'warning'
          });
        }
      });
    },
    goDetail: function goDetail(id) {
      var routeUrl = this.$router.resolve({
        path: 'industryDetail',
        query: {
          id: id
        }
      });
      window.open(routeUrl.href, '_blank');
    },
    goHome: function goHome() {
      this.$router.push('home');
    },
    // 退出登录
    logout: function logout() {
      window.localStorage.clear();
      this.$router.replace('home');
    }
  }
};